import { useEffect } from "react"
import { NavLink } from "react-router-dom"
import { RiArrowRightDoubleLine } from "react-icons/ri";
import { IoIosArrowDropright } from "react-icons/io";
import img8 from "../img/productos/toldos/home.png";
import { imgCarouselHome } from "../helper/Images";
import CarouselHome from "../hooks/CarouselHome";
import img1 from "../img/home/motor.png";
import img2 from "../img/home/duelas.png";
import img3 from "../img/home/accesorios.png";
import img4 from "../img/home/elevador.png";
import img5 from "../img/home/telas.png";
import img6 from "../img/home/sombrillas.png";
import img7 from "../img/cambn/18.png";
import img9 from "../img/productos/muebles/4.png";
import img10 from "../img/productos/cortinas/Cortina_color_Madera.png"
import img11 from "../img/productos/pergolas/home.png"
import SubFooter from "./SubFooter";
//import Catalogo from "./Catalogo";

const Home = ({ setOther }) => {

    useEffect(() => {
        setOther(false)
    }, [setOther])

    return (
        <>
            <CarouselHome content={imgCarouselHome} />

            <section className="know-more" >
                <div className="container d-flex justify-content-center overflow-hidden">
                    <div className="d-flex" data-aos="zoom-in">
                        <span className="d-flex align-items-center fw-bold">
                            <RiArrowRightDoubleLine size={50} />
                        </span>
                        <h1 className="mb-0">Conoce más acerca de DICET</h1>
                    </div>
                </div>
            </section>


            <section className="section-curtain-motors">
                <div className="container overflow-hidden " >
                    <div className="d-flex gap-3 flexWrapReverse " data-aos="fade-right">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img1} alt="Motores para cortinas" />
                            </figure>
                        </div>
                        <div className="d-flex flex-column justify-content-center">
                            <h2 className="text-uppercase fw-bold title-sections">motores para cortinas</h2>
                            <p>
                                Nuestros motores se caracterizan por su alta calidad, durabilidad y eficiencia, ​lo que los convierte en la elección ideal para todo tipo de cortinas metálicas, ​ya sea para uso comercial, industrial o residencial.
                            </p>
                            <div className="d-flex gap-1 align-items-center">
                                <span className="arrow-round-green">
                                    <IoIosArrowDropright size={30} />
                                </span>
                                <NavLink to="/motores">
                                    <span className="fw-bold">Más información </span>
                                </NavLink>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section-curtain-staves">
                <div className="container overflow-hidden">
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div className="d-flex flex-column justify-content-center">
                            <h2 className="text-uppercase fw-bold title-sections">duelas para cortinas</h2>
                            <p>
                                Amplia distribución en Guatemala, El Salvador y Honduras de duelas, 
                                <br />accesorios, cadenas, guías y chapas para cortinas metálicas.</p>
                            <p> Distintos mod​elos y
                                diseños para todos los gustos y necesi​dades de tus clientes. </p>
                            <div className="d-flex gap-1 align-items-center">
                                <span className="arrow-round-green">
                                    <IoIosArrowDropright size={30} />
                                </span>
                                <NavLink to="/">
                                    <span className="fw-bold">Más información </span>
                                </NavLink>
                            </div>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img2} alt="Duelas para cortinas" />
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section-curtain-metal">
                <div className="container overflow-hidden">
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img10} alt="Cortinas Metálicas" />
                            </figure>
                        </div>
                        <div className="d-flex flex-column justify-content-center">
                            <h2 className="text-uppercase fw-bold title-sections">cortinas metálicas</h2>
                            <p>
                                Amplia distribución en Guatemala, El Salvador y Honduras de duelas, ​accesorios, <br />cadenas, guías y chapas para cortinas metálicas, distintos mod​elos y diseños para todos los gustos y necesi​dades de tus clientes.
                            </p>
                            <div className="d-flex gap-1 align-items-center">
                                <span className="arrow-round-green">
                                    <IoIosArrowDropright size={30} />
                                </span>
                                <NavLink to="/cortinas">
                                    <span className="fw-bold">Más información </span>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section-rollers">
                <div className="container overflow-hidden">
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div className="d-flex flex-column justify-content-center">
                            <h2 className="text-uppercase fw-bold title-sections">mecanismos para rollers</h2>
                            <p>
                                En DICET encuentras una amplia variedad de mecanismos, motores, herrajes ​y
                                accesorios para rollers interior y exterior, nos especializamos en la venta
                                a ​distribuidores y fabricantes, hacemos envío a todo el país.
                            </p>
                            <div className="d-flex gap-1 align-items-center">
                                <span className="arrow-round-green">
                                    <IoIosArrowDropright size={30} />
                                </span>
                                <NavLink to="/rollers/accesorios">
                                    <span className="fw-bold">Más información </span>
                                </NavLink>
                            </div>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img3} alt="Mecanismos para rollers" />
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section-parking-elevators">
                <div className="container overflow-hidden">
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img4} alt="Elevadores de parqueo" />
                            </figure>
                        </div>
                        <div className="d-flex flex-column justify-content-center">
                            <h2 className="text-uppercase fw-bold title-sections">elevadores de parqueo</h2>
                            <p>
                                Distribuidores oficiales de la marca Mutrade, los elevadores de parqueo ​más comerciales en Asia y en Europa.
                            </p>
                            <div className="d-flex gap-1 align-items-center">
                                <span className="arrow-round-green">
                                    <IoIosArrowDropright size={30} />
                                </span>
                                <NavLink to="/elevadores">
                                    <span className="fw-bold">Más información </span>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section-italian-fabrics">
                <div className="container overflow-hidden">
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div className="d-flex flex-column justify-conten t-center">
                            <h2 className="text-uppercase fw-bold title-sections">telas acrílicas 100% italianas</h2>
                            <p>
                                Amplia variedad de estilos, texturas y diseños de telas para exteriores ​e
                                interiores ideales para toldos y sombrillas tales
                                como: Screen, ​Blackout, Tipo Zebra y Sarán.
                            </p>
                            <div className="d-flex gap-1 align-items-center">
                                <span className="arrow-round-green">
                                    <IoIosArrowDropright size={30} />
                                </span>
                                <NavLink to="/telas">
                                    <span className="fw-bold">Más información </span>
                                </NavLink>
                            </div>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img5} alt="Telas Italianas" />
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section-umbrella-structure">
                <div className="container overflow-hidden">
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img6} alt="sombrillas económicas" />
                            </figure>
                        </div>
                        <div className="d-flex flex-column justify-content-center">
                            <h2 className="text-uppercase fw-bold title-sections">sombrillas económicas</h2>
                            <p>
                                Nuestro equipo de expertos se dedica a crear las mejores ​estructuras de aluminio para sombrillas, diseñadas para brindar ​resistencia, durabilidad y estilo a tus espacios al aire libre.
                            </p>
                            <div className="d-flex gap-1 align-items-center">
                                <span className="arrow-round-green">
                                    <IoIosArrowDropright size={30} />
                                </span>
                                <NavLink to="/estructuras">
                                    <span className="fw-bold">Más información </span>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section-rollers">
                <div className="container overflow-hidden">
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div className="d-flex flex-column justify-content-center">
                            <h2 className="text-uppercase fw-bold title-sections">ROLLERS DE INTERIOR ​Y EXTERIOR</h2>
                            <p>
                                Nuestros Rollers para interiores y exteriores bloquean la luz solar y, al ​mismo tiempo, <br />permiten la entrada de luz natural en la habitación ​dependiendo el estilo de cada cortina
                            </p>
                            <div className="d-flex gap-1 align-items-center">
                                <span className="arrow-round-green">
                                    <IoIosArrowDropright size={30} />
                                </span>
                                <NavLink to="/rollers">
                                    <span className="fw-bold">Más información </span>
                                </NavLink>

                            </div>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img7} alt="rollers de exteriores" />
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section-toldos">
                <div className="container overflow-hidden">
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img8} alt="Toldos Retráctiles" />
                            </figure>
                        </div>
                        <div className="d-flex flex-column justify-content-center">
                            <h2 className="text-uppercase fw-bold title-sections">toldos retráctiles</h2>
                            <p>
                                Son toldos retráctiles ideales para crear sombra según se necesite <br />y ​proteger la lona de las condiciones climáticas.
                            </p>
                            <div className="d-flex gap-1 align-items-center">
                                <span className="arrow-round-green">
                                    <IoIosArrowDropright size={30} />
                                </span>
                                <NavLink to="/toldos">
                                    <span className="fw-bold">Más información </span>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section-muebles-italian">
                <div className="container overflow-hidden">
                    <div className="d-flex gap-3 flexWrap" data-aos="fade-left">
                        <div className="d-flex flex-column justify-content-center">
                            <h2 className="text-uppercase fw-bold title-sections">muebles económicos</h2>
                            <p>
                                Cotiza con nosotros tus muebles de jardín y de exterior:<br /> sillas, mesas, ​tumbonas, sofás, taburetes y accesorios. Descubre nuestros Muebles Económicos.
                            </p>
                            <div className="d-flex gap-1 align-items-center">
                                <span className="arrow-round-green">
                                    <IoIosArrowDropright size={30} />
                                </span>
                                <NavLink to="/muebles">
                                    <span className="fw-bold">Más información </span>
                                </NavLink>
                            </div>
                        </div>
                        <div>
                            <figure>
                                <img className="w-100 h-100" src={img9} alt="Muebles Italaianos" />
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section-pergolas">
                <div className="container overflow-hidden">
                    <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
                        <div>
                            <figure>
                                <img className="w-400 h-400" src={img11} alt="Pergolas" />
                            </figure>
                        </div>
                        <div className="d-flex flex-column justify-content-center">
                            <h2 className="text-uppercase fw-bold title-sections">Pérgolas</h2>
                            <p>
                            Cotiza con nosotros tus pérgolas o las de tu clientes pueden cubrir un patio o<br /> 
                            un camino que generalmente enlaza el jardín con la vivienda.
                            </p>
                            <div className="d-flex gap-1 align-items-center">
                                <span className="arrow-round-green">
                                    <IoIosArrowDropright size={30} />
                                </span>
                                <NavLink to="/pergolas">
                                    <span className="fw-bold">Más información </span>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <SubFooter/>
           {/*<Catalogo secction={'home'}/>*/}
        </>
    )
}

export default Home