import DuelasAluminioAccesorios from "./components/DuelasAluminioAccesorios";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import CortinasAccesorios from "./components/CortinasAccesorios";
import RollersAccesorios from "./components/RollersAccesorios";
import DuelasAccesorios from "./components/DuelasAccesorios";
import PercianaMetalica from "./components/PercianaMetalica";
import DuelasEstilos from "./components/DuelasEstilos";
import Estructuras from "./components/Estructuras";
import { useLayoutEffect, useState } from "react";
import Elevadores from "./components/Elevadores";
import Cortinas from "./components/Cortinas";
import Rollers from "./components/Rollers";
import Motores from "./components/Motores";
import Muebles from "./components/Muebles";
import Contact from "./components/Contact";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Toldos from "./components/Toldos";
import Duelas from "./components/Duelas";
import Telas from "./components/Telas";
import About from "./components/About";

import Pergolas from "./components/Pergolas";
import Home from "./components/Home";
import { useEffect } from "react";
import AOS from 'aos';
import MotoresRib from "./components/MotoresRib";
import MotoresNice from "./components/MotoresNice";
import MotoresJielong from "./components/MotoresJielong";
import MotoresTubulares from "./components/MotoresTubulares";
import Hawaina from "./components/Hawaina";

function App() {

  useEffect(() => {
    AOS.init({ duration: 3000 });
  }, []);

  const [otherLoc, setDisplayOther] = useState(false)

  const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
  };

  return (
    <>
      <Header varOther={otherLoc} setOther={setDisplayOther} />
      <Wrapper>
        <Routes>
          <Route path="/" element={<Home setOther={setDisplayOther} />} />
          <Route path="/about" element={<About setOther={setDisplayOther} />} />
          <Route path="/cortinas" element={<Cortinas setOther={setDisplayOther} />} />
          <Route path="/cortinas/accesorios" element={<CortinasAccesorios setOther={setDisplayOther} />} />
          <Route path="/cortinas/hawainas" element={<Hawaina setOther={setDisplayOther} />} />
          <Route path="/motores" element={<Motores setOther={setDisplayOther} />} />
          <Route path="/motores/tubulares" element={<MotoresTubulares setOther={setDisplayOther} />} />
          <Route path="/motores/nice" element={<MotoresNice setOther={setDisplayOther} />} />
          <Route path="/motores/rib" element={<MotoresRib setOther={setDisplayOther} />} />
          <Route path="/motores/jielong" element={<MotoresJielong setOther={setDisplayOther} />} />
          <Route path="/muebles" element={<Muebles setOther={setDisplayOther} />} />
          <Route path="/mecanismos/percianas/metalicas" element={<PercianaMetalica setOther={setDisplayOther} />} />
          <Route path="/duelas" element={<Duelas setOther={setDisplayOther} />} />
          <Route path="/duelas/accesorios" element={<DuelasAccesorios setOther={setDisplayOther} />} />
          <Route path="/duelas/estilos" element={<DuelasEstilos setOther={setDisplayOther} />} />
          <Route path="/duelas/aluminio/accesorios" element={<DuelasAluminioAccesorios setOther={setDisplayOther} />} />
          <Route path="/elevadores" element={<Elevadores setOther={setDisplayOther} />} />
          <Route path="/estructuras" element={<Estructuras setOther={setDisplayOther}/>} />
          <Route path="/toldos" element={<Toldos setOther={setDisplayOther} />} />
          <Route path="/pergolas" element={<Pergolas setOther={setDisplayOther} />} />
          <Route path="/telas" element={<Telas setOther={setDisplayOther} />} />
          <Route path="/rollers" element={<Rollers setOther={setDisplayOther} />} />
          <Route path="/rollers/accesorios" element={<RollersAccesorios setOther={setDisplayOther} />} />
          <Route path="/contact" element={<Contact setOther={setDisplayOther} />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Wrapper>


      <Footer />



    </>
  );
}


export default App;

