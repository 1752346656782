import retractil from "../img/productos/pergolas/g1.gif"
import portada from "../img/productos/pergolas/portrait.png";
import portada2 from "../img/productos/pergolas/perg1.png";
import portada3 from "../img/productos/pergolas/4.png";
import "../css/carrusel.css"

import { imgCarouselTelas2 } from "../helper/ImagesPergolas";


import portada4 from "../img/productos/pergolas/calidad.png";
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import img1 from "../img/productos/pergolas/pergolabody1.png";
import img2 from "../img/productos/pergolas/pergolabody2.png";
import img3 from "../img/productos/toldos/1.png";
import img4 from "../img/productos/toldos/2.png";
import img5 from "../img/productos/toldos/3.png";
import img6 from "../img/productos/toldos/4.png";
import img7 from "../img/productos/toldos/7.png";
import img8 from "../img/productos/toldos/6.png";
import img9 from "../img/productos/toldos/8.png";
import img10 from "../img/productos/toldos/9.png";
import video1 from "../img/productos/toldos/29.mp4";
import video2 from "../img/productos/toldos/28.mp4";
import CotizaOption from "../hooks/CotizaOption";
import BtnCotiza from "../hooks/BtnCotizar";
import { GoDotFill } from "react-icons/go";
import { useEffect, useRef } from "react";

const Toldos = ({ setOther }) => {

    const refManuales = useRef(null)
    const refMonitorizados = useRef(null)

    useEffect(() => {
        setOther(false);
    }, [setOther]);

    const scrollManuales = () => refManuales.current.scrollIntoView({ block: "end" })
    const scrollMonitorizados = () => refManuales.current.scrollIntoView({ block: "start" })  

    return (
        <>
            <section>
                <figure>
                    <img src={portada} className="w-100 h-100" alt="Portada Toldos Retráctiles"/>
                </figure>
            </section>

            <section className="p-0 m-0 mb-5 overflow-hidden">
                <div className=" container text-center" data-aos="zoom-in">
                <h2> </h2>
                    <h2 className="etitulo alinearletraselevador m-0 text-uppercase">pérgolas</h2>
                </div>
            </section>



            <section cassName="p-0 m-0">
                <figure>
                    <img src={portada2} className="w-100 h-100" alt="Portada Perg2"/>
                </figure>
            </section>
            








            <section className="mb-5">
        <div className="carrusel-container">
          <div className="carrusel-track">
            {/* Primer conjunto de imágenes */}
            {imgCarouselTelas2.map((data, key) => (
              <figure key={key} className="carrusel-item">
                <img src={data[0] || "/placeholder.svg"} alt={data[1]} />
              </figure>
            ))}
            {/* Conjunto duplicado para desplazamiento continuo */}
            {imgCarouselTelas2.map((data, key) => (
              <figure key={`duplicate-${key}`} className="carrusel-item">
                <img src={data[0] || "/placeholder.svg"} alt={data[1]} />
              </figure>
            ))}
          </div>
        </div>
      </section>











            <section className="daralinicacio overflow-hidden">
                <div className="container">
                    <div className="d-flex flex-column" data-aos="zoom-in">
                        <div className="mb-3">
                            <h2 className="texttitulo text-center">Medidas Disponibles</h2>
                        </div>
                        <div className="d-flex flex-column align-items-center">
                            <div className="d-flex gap-1 align-items-center">
                                <span className="arrow-round-green">
                                    <GoDotFill size={30} />
                                </span>
                                <span className="cortinasp">4.00x4.00 metros</span>
                            </div>
                            <div className="d-flex gap-1 align-items-center">
                                <span className="arrow-round-green">
                                    <GoDotFill size={30} />
                                </span>
                                <span className="cortinasp">4.00x3.00 metros</span>
                            </div>
                            <div className="d-flex gap-1 align-items-center">
                                <span className="arrow-round-green">
                                    <GoDotFill size={30} />
                                </span>
                                <span className="cortinasp">4.00x2.00 metros</span>
                            </div>
                            <div className="d-flex gap-1 align-items-center">
                                <span className="arrow-round-green">
                                    <GoDotFill size={30} />
                                </span>
                                <span className="cortinasp">3.00x3.00 metros</span>
                            </div>
                            <BtnCotiza/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="daralinicacio overflow-hidden">
      <div className="container">
        <div className="d-flex gap-3 flexWrapReverse" data-aos="fade-right">
          <div className="d-flex flex-column justify-content-center w-100 telefono-w-100">
          <h2 className="texto_tittfica2 sbtxt-movil2">
        <span style={{ color: "black" }}>¿Te gustaría embellecer tu</span>{" "}
        <strong style={{ color: "#008000" }}>jardín, terraza o cualquier espacio al aire libre?</strong>
      </h2>
            <p className="cortinasp">
              Te ofrecemos una variedad de estilos para que elijas el que más te guste o se ajuste a tus expectativas,
              logrando así el ambiente que deseas.
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="d-flex justify-content-center">
          {" "}
          <figure className="m-0">
            {" "}
            <img
              width="600"
              height="600"
              src={retractil || "/placeholder.svg"}
              alt="Toldos retráctiles"
              style={{ maxWidth: "100%", height: "auto" }} 
            />
          </figure>
        </div>
      </div>
    </section>

    <section className="daralinicacio overflow-hidden m-0 p-0">
                <figure>
                    <img src={portada4} className="w-100 h-100" alt="Portada Perg2"/>
                </figure>
                
            </section>
    
            <section ref={refManuales} className="daralinicacio overflow-hidden">
      <div className="container">
      <div className="d-flex justify-content-center align-items-start gap-3 flex-wrap" data-aos="fade-right">
          <div>
            <figure className="m-0">
              <img
                width="550px"
                height="600px"
                src={img1 || "/placeholder.svg"}
                alt="Toldos Manuales"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </figure>
          </div>
          <div>
            <figure className="m-0">
              <img
                width="550px"
                height="600px"
                src={img2 || "/placeholder.svg"}
                alt="Toldos Manuales"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </figure>
          </div>
          
        </div>
      </div>
    </section>
            
            

            
    

            <CotizaOption title={'¡Cotiza hoy mismo!'}/>
        </>
    );


};

export default Toldos