/******************************* Images Carousel Home *********************************/
import carouselTelas1 from '../img/productos/pergolas/c1.png';
import carouselTelas2 from '../img/productos/pergolas/c2.png';
import carouselTelas3 from '../img/productos/pergolas/c3.png';
import carouselTelas4 from '../img/productos/pergolas/c4.png';
import carouselTelas5 from '../img/productos/pergolas/c5.png';

const imgCarouselTelas2 = [
    [
        carouselTelas1,
        "Pimer portada",

    ],
    [
        carouselTelas2,
        "Segunda portada",
    ],
    [
        carouselTelas3,
        "Tercera portada",
    ],
    [
        carouselTelas4,
        "Curta portada",
    ],
    [
        carouselTelas5,
        "Quinta portada",
    ],
]



export { imgCarouselTelas2 }