const url = process.env.REACT_APP_API_CONTACT

const response = async (data) => {
    if (!url) {
        console.error('REACT_APP_API_CONTACT no está definido');
        throw new Error('URL de API no configurada');
      }
    
      console.log('Enviando petición a:', url);


    const response = await fetch(url,{ 
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
        },
        body:JSON.stringify(data)
    });
    return response
}

export default response